import moment from 'moment';
import ZdhcServiceV2 from '../services/Zdhc/ZdhcServiceV2.js';
import ZdhcServiceV5 from '../services/Zdhc/ZdhcServiceV5.js';
import ProductService from '../services/ProductService';
import ZdhcProductService from '../services/ZdhcProductService';
import IncheckReferenceService from '../services/IncheckReferenceService.js';
import GoogleService from '../services/GoogleService.js';
import { reactive, ref } from 'vue';
const useMatchAlgorithm = () => 
{
    /**
     * 
     * Data
     * 
     */
    const counter = ref();
    const product = reactive({});
    const { /* searchExactMatch,  */storeExactMatch } = ProductService();
    const { translate } = GoogleService();
    const { storeZdhcProduct, deleteZdhcProduct } = ZdhcProductService();
    const { searchZdhcProductByZdhcPid} = ZdhcServiceV5();
    const { searchZdhcProductByWord, searchByFormulator, getProductByGuid, storeNewProduct: storeNewZdhcProductV2 } = ZdhcServiceV2();
    const { updatePreviousResults, setNoMatchInIncheckReference } = IncheckReferenceService();
    /**
     * 
     * Methods
     * 
     */
    const assignProduct = async(p) => 
    {
        Object.assign(product, p);
    }
    const checkExistingMatch = async (p) => 
    {
        Object.assign(product, p);
        /* const exactMatchRes = await checkExactMatch();
        return exactMatchRes; */
       
        if(product.pid)
        {
            const zdhcExactMatchRes = await searchZdhcProductByZdhcPid(product.pid);
            if(zdhcExactMatchRes?.totalResults == 1)
            {
                if(product.incheck_reference.zdhc_product_id && zdhcExactMatchRes.data[0].productID && product.incheck_reference.zdhc_product_id == zdhcExactMatchRes.data[0].productID)
                {
                    return {
                        status: 'ok',
                        product: product,
                        productStatus: 'success'
                    };
                }
                let true_match = {
                    id: zdhcExactMatchRes.data[0].productID,
                    name: zdhcExactMatchRes.data[0].productName,
                    guid: zdhcExactMatchRes.data[0].productGUID,
                    reference_date: product.incheck_reference.reference_date,
                    fullProduct:
                    {
                        productID: zdhcExactMatchRes.data[0].productID ?? null,
                        registered: zdhcExactMatchRes.data[0].source ?? null,
                        lastUpdatedDate: zdhcExactMatchRes.data[0].lastUpdatedDate ?? null,
                        productCode: zdhcExactMatchRes.data[0].productCode ?? null,
                        OtherName: zdhcExactMatchRes.data[0].OtherName ?? null,
                        productOtherName: zdhcExactMatchRes.data[0].productOtherName ?? null,
                        zdhcPID: zdhcExactMatchRes.data[0].zdhcPID ?? null,
                        zdhcAID: zdhcExactMatchRes.data[0].zdhcAID ?? null,
                        ProductCertifications: zdhcExactMatchRes.data[0].productCertifications ?? []
                    }
                }
                const storeExactMatchRes = await storeExactMatch(product.id, true_match);
                if(storeExactMatchRes)
                {
                    product.incheck_references = storeExactMatchRes.data.p.incheck_references;
                    product.incheck_reference = storeExactMatchRes.data.p.incheck_reference;
                    product.formulator = storeExactMatchRes.data.p.formulator;
                }
                return {
                    status: 'ok',
                    product: product,
                    productStatus: 'success'
                };
            }
        }
        else 
        {
            return {
                status: 'ok',
                productStatus: 'no_pid'
            }
        }
        // If product doesn't have PID, it's a no match
        if(!product.incheck_reference.no_match_id)
        {
            const formulatorGuid = product.formulator ? product.formulator.guid : null;
            
            const res = await storeNewZdhcProductV2(product.name, formulatorGuid);
            if(res.guid)
            {
                const noMatchRes = await setNoMatchInIncheckReference(product.incheck_reference.id, {guid: res.guid, new_results: null});
                if(noMatchRes.incheck_reference)
                {
                    product.incheck_reference = noMatchRes.incheck_reference;
                    return {
                        status: 'ok',
                        product: product,
                        productStatus: 'manualNoMatch'
                    };
                }
            }
        }
        else return {status: 'ok'};

        return {status: 'ko'};

    };
    const checkExactMatch = async () => 
    {
        let hasInventory = checkForInventory(product);
        if(!hasInventory)
        {
            if(product.formulator || product.formulator_name)
            {
                let formulator_name = product.formulator ? product.formulator.name : product.formulator_name;
                const res = await searchByFormulator(product.name, formulator_name.toLowerCase())
                if(res) 
                {
                    let availableMatches = [];
                    if(res.length)
                    {
                        availableMatches = res.filter(el =>
                        {
                            return el.fullProduct.productName.toLowerCase() == product.name.toLowerCase() && el.fullProduct.formulatorName.toLowerCase() == formulator_name.toLowerCase();
                        });
                        if(availableMatches.length == 1)
                        {
                            let true_match = availableMatches[0];
                            true_match.reference_date = product.incheck_reference.reference_date;
                            const storeRes = await storeExactMatch(product.id, true_match);
                            if(storeRes)
                            {
                                product.incheck_references = storeRes.data.p.incheck_references;
                                product.incheck_reference = storeRes.data.p.incheck_reference;
                                product.formulator = storeRes.data.p.formulator;
                            }
                            return {
                                status: 'ok',
                                product: product,
                                productStatus: 'success'
                            };
                            
                        }
                    }
                    /* if(res.length === 1)
                    {
                        let true_match = res[0];
                        console.log('3 - ', true_match);
                        
                        if(true_match.fullProduct
                            && true_match.fullProduct.formulatorGUID
                            && product.formulator.guid
                            && true_match.name.toLowerCase() === product.name.toLowerCase())
                        {
                            true_match.reference_date = product.incheck_reference.reference_date;
                            const storeRes = await storeExactMatch(product.id, true_match);
                            if(storeRes)
                            {
                                product.incheck_references = storeRes.data.p.incheck_references;
                                product.incheck_reference = storeRes.data.p.incheck_reference;
                                product.formulator = storeRes.data.p.formulator;
                            }
                            return {
                                status: 'ok',
                                product: product,
                                productStatus: 'success'
                            };
                        }
                    } */
                }
            }
            if(product.incheck_reference.no_match_id)
            {
                /* If product has NoMatch status, check for new ZDHC products */
                return checkNoMatchNewAssociables();
            }
        
            return {
                status: 'ko',
            };
        }
        else 
        {
            if(product.incheck_reference.match)
            {
                return {
                    status: 'ok',
                    productStatus: 'success'
                }
            }
            else if(product.incheck_reference.suggestion_id) 
            {
                return {
                    status: 'ok',
                    productStatus: 'suggestion'
                }
            }
            else if(product.incheck_reference.no_match_id) 
            {
                return {
                    status: 'ok',
                    productStatus: 'manualNoMatch'
                }
            }
        }
    };
    const checkForInventory = () => 
    {
        let hasInventory = false;
        if(Object.keys(product).length)
        {
            let incheck_date = moment(product.incheck_reference.reference_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            if(product.inventories.length)
            {
                hasInventory = product.inventories.some( i => 
                {
                    let inventory_date = moment(i.reference_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
                    return moment(inventory_date).isSame(moment(incheck_date));
                });
            }
        }
        return hasInventory;
    };
    // Search for matches - first phase
    const searchMatchesFirstPhase = async () => 
    {
        let matches = [];
        let matchId = '';
        if(product.incheck_reference?.zdhc_product_id) matchId = product.incheck_reference.zdhc_product_id
        else if(product.incheck_reference?.suggestion_id)  matchId = product.incheck_reference.suggestion.zdhc_product_id;

        let zdchRes = await searchZdhcProductByWord(product.name);

        if(zdchRes)
        { 
            zdchRes = zdchRes.filter(p => 
            {
                return p.id === matchId || (p.formulator_name !== 'No match Formulator' && (p.fullProduct?.registered && p.fullProduct?.registered === 'ZDHC Gateway'));
            });
            zdchRes.forEach( p => {if(p.id === matchId || p.formulator_name !== 'No match Formulator') matches.push(p);});
        }
        return matches;
    };
    const searchMatchesSecondPhase = async () => 
    {
        let matches = [];
        let matchId = '';
        if(product.incheck_reference?.zdhc_product_id) matchId = product.incheck_reference.zdhc_product_id
        else if(product.incheck_reference?.suggestion_id)  matchId = product.incheck_reference.suggestion.zdhc_product_id;
        // 1 - Translate the product name (EN)
        let googleRes = await translate(product.name);
        if(googleRes)
        {
            let translated_name = googleRes.data.translations[0].translatedText;
            // 2 - Search match into zdhc records 
            let zdhcRes = await searchZdhcProductByWord(translated_name);
            if(zdhcRes)
            {
                zdhcRes = zdhcRes.filter(p => 
                {
                    return p.id === matchId || (p.formulator_name !== 'No match Formulator' && (p.fullProduct?.registered && p.fullProduct?.registered === 'ZDHC Gateway'));
                });
                zdhcRes.forEach( p => {if(p.id === matchId || p.formulator_name !== 'No match Formulator') matches.push(p);});
            }
        }
        return matches;
    };
    const searchMatchesThirdPhase = async () => 
    {
        let matches = [];
        let matchId = '';
        let splittedName = product.name.split(' ');
        if(product.incheck_reference?.zdhc_product_id) matchId = product.incheck_reference.zdhc_product_id
        else if(product.incheck_reference?.suggestion_id)  matchId = product.incheck_reference.suggestion.zdhc_product_id;

        splittedName = splittedName.filter( s => s.length > 2);

        const totalLength =  splittedName.length;
        let actualResLength = 0;
        //let totalSuggestionsRes = [];
        if(totalLength)
        {
            for (const splitted_word of splittedName) 
            {
                // Get matches for 'word'
                let zdhcRes = await searchZdhcProductByWord(splitted_word);
                // ZDHC matches
                if(zdhcRes) 
                {
                    actualResLength +=1;
                    if(zdhcRes.length) 
                    {
                        zdhcRes = zdhcRes.filter(p => 
                        {
                            return p.id === matchId || (p.formulator_name !== 'No match Formulator' && (p.fullProduct?.registered && p.fullProduct?.registered === 'ZDHC Gateway'));
                        });
                        zdhcRes.forEach( p => {if(p.id === matchId || p.formulator_name !== 'No match Formulator') matches.push(p);});
                    }
                }
                if(actualResLength === totalLength) return matches;
              }
        }
        else return matches;
    };
    const checkForExistingZdhcProduct = async (products) => 
    {
        counter.value = 0;
        try 
        {
            for(let i = 0; i < products.length; i++)
            {
                counter.value += 1;
                let p = products[i];
                let incheck_reference = p.incheck_references[0];
                let zdhcGuid = incheck_reference.no_match_id 
                                ? incheck_reference.no_match_id
                                : (incheck_reference.zdhc_product_id 
                                    ? incheck_reference.match?.guid 
                                    : (incheck_reference.suggestion_id 
                                        ? incheck_reference.suggestion?.zdhc_product?.guid
                                        : ''));

                if(zdhcGuid)
                {
                    let source = incheck_reference.zdhc_product_id || incheck_reference.suggestion_id ? true : false;
                    
                    const res = await getProductByGuid(zdhcGuid, source);
                    if(res.data?.result?.errorMessage && res.data?.result?.errorMessage === 'No results found.')
                    {
                        // Delete Zdhc Product
                        deleteZdhcProduct(zdhcGuid);
                    }
                    else
                    {
                        // Store Zdhc Product
                        let zp = res?.data?.data[0];
                        translate(zp.productName)
                        .then( async (translation_res) => 
                        {
                            let translated_name = translation_res.data.translations[0].translatedText;
                            let detected_lang = translation_res.data.translations[0].detectedSourceLanguage;
                            if(zp.productName.toLowerCase() === translated_name.toLowerCase())
                            {
                                zp.translated_names = { [detected_lang] :  zp.productName.toLowerCase() };
                            }
                            else 
                            {
                                zp.translated_names = 
                                { 
                                    [detected_lang] :  zp.productName.toLowerCase(),
                                    'en' : translated_name.toLowerCase(),
                                };
                            }
                            storeZdhcProduct(zp);
                        });
                    }
                }
            }
            if(counter.value == products.length) return {status: 'success'};
            else return {status: 'error'};
        }
        catch(e)
        {
            console.log(e)
            return {status: 'error'};
        }
    };
    const checkNoMatchNewAssociables =  async () => 
    {
        let guids = [];
        let res;
        res = await searchMatchesFirstPhase();
        if(res.length)
        {
            res.forEach(p => {if(!guids.includes(p.guid)) guids.push(p.guid)});
        }
        else 
        {
            res = await searchMatchesSecondPhase();
            if(res.length)
            {
                res.forEach(p => {if(!guids.includes(p.guid)) guids.push(p.guid)});
            }
            else 
            {
                res = await searchMatchesThirdPhase();
                if(res.length)
                {
                    res.forEach(p => {if(!guids.includes(p.guid)) guids.push(p.guid)});
                }
            }
        }

        if(guids)
        {
            const res = await updatePreviousResults(product.incheck_reference.id, {new_results: [...guids]});
            if('incheck_reference' in res)
            {
                product.incheck_reference = res.incheck_reference;
                return {
                    status: 'ok',
                    product: product,
                };
            }
        }
        return {
            status: 'ko',
        };
    }

    return {
        /* Data */
        counter,
        /* Methods */
        assignProduct,
        checkExistingMatch,
        checkExactMatch,
        searchMatchesFirstPhase,
        searchMatchesSecondPhase,
        searchMatchesThirdPhase,
        checkForExistingZdhcProduct
    };
};

export default useMatchAlgorithm;